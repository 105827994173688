<template>
	<div id="myQuizList" v-loading="loading" :element-loading-text="loadingText"
		element-loading-background="rgba(0, 0, 0, 0)">
		<!-- 查询 -->
		<div class="search">
			<el-row :gutter="10">
				<el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4">
					<el-select v-model="scopeId" filterable placeholder="请选择考试范围" class="selectStyle" clearable>
						<el-option v-for="item in scopeData" :key="item.id" :label="item.scope" :value="item.id">
						</el-option>
					</el-select>
				</el-col>
				<el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4">
					<el-select v-model="difficulty" filterable placeholder="请选择试题难度" class="selectStyle" clearable>
						<el-option v-for="item in difficultyData" :key="item.id" :label="item.difficulty"
							:value="item.id">
						</el-option>
					</el-select>
				</el-col>
				<el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4">
					<el-select v-model="type" filterable placeholder="请选择试题类型" class="selectStyle" clearable>
						<el-option v-for="item in typeData" :key="item.id" :label="item.type" :value="item.id">
						</el-option>
					</el-select>
				</el-col>
				<el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4">
					<!-- @input="keywordValue('contentKeyword',contentKeyword)" -->
					<el-input v-model="contentKeyword" placeholder="请输入题目关键字"></el-input>
				</el-col>
				<el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4">
					 <!-- @input="keywordValue('optionKeyword',optionKeyword)" -->
					<el-input v-model="optionKeyword" placeholder="请输入选项关键字"></el-input>
				</el-col>
				<el-col :xs="12" :sm="12" :md="8" :lg="12" :xl="4" class="searchStyle">
					<el-button size="small" class="searchButton" @click="searchClick">查询</el-button>
					<el-button size="small" type="warning" @click="updateScope" v-show="!checkBoxShow">更新考试范围
					</el-button>
					<el-button size="small" type="primary" v-show="checkBoxShow" @click="confirmUpdateScope">确定
					</el-button>
					<el-button size="small" type="infor" v-show="checkBoxShow" @click="cancelUpdateScope">取消</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- 列表 -->
		<div class="quizListTable">
			<el-table :cell-style="rowClass" ref="multipleTable" :header-cell-style="headClass" :data="quizData" border
				stripe @selection-change="handleSelectionChange" :row-key="getRowKeys">
				<template slot="empty">
					<span v-show="loading">加载中...</span>
					<span v-show="!loading">暂无数据</span>
				</template>
				<el-table-column type="selection" width="55" v-if="checkBoxShow" :reserve-selection="true">
				</el-table-column>
				<el-table-column type="index" :index="indexMethod" label="#" width="60"></el-table-column>
				<el-table-column prop="content" label="问题" :show-overflow-tooltip="true" width="180">
					<template scope="scope">{{scope.row.content || '--'}}</template>
				</el-table-column>
				<el-table-column prop="typeStr" label="类型" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.typeStr || '--'}}</template>
				</el-table-column>
				<el-table-column prop="scope" label="范围" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.scope || '--'}}</template>
				</el-table-column>
				<el-table-column prop="difficultyStr" label="难度" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.difficultyStr || '--'}}</template>
				</el-table-column>
				<el-table-column prop="author" label="出题人" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.author || '--'}}</template>
				</el-table-column>
				<el-table-column prop="optionA" label="选项A" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.optionA || '--'}}</template>
				</el-table-column>
				<el-table-column prop="optionB" label="选项B" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.optionB || '--'}}</template>
				</el-table-column>
				<el-table-column prop="optionC" label="选项C" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.optionC || '--'}}</template>
				</el-table-column>
				<el-table-column prop="optionD" label="选项D" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.optionD || '--'}}</template>
				</el-table-column>
				<el-table-column prop="optionE" label="选项E" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.optionE || '--'}}</template>
				</el-table-column>
				<el-table-column prop="optionF" label="选项F" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.optionF || '--'}}</template>
				</el-table-column>
				<el-table-column prop="answer" label="答案" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.answer || '--'}}</template>
				</el-table-column>
				<el-table-column prop="tips" label="提示" :show-overflow-tooltip="true">
					<template scope="scope">{{scope.row.tips || '--'}}</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="100">
					<template slot-scope="scope">
						<el-button type="text" style="color:#e6a23c;" @click="editClick(scope.$index,scope.row)">编辑
						</el-button>
						<el-button type="text" style="color:#f56c6c;" @click="deleteClick(scope.$index,scope.row)">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 编辑弹框 -->
		<el-dialog title="编辑试题" :visible.sync="dialogQuizEdit" :close-on-click-modal="false" class="editStyle"
			:beforeClose="beforeCloseEdit">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="问题内容" prop="content" ref="content">
					<el-input type="textarea" v-model="ruleForm.content" @blur="contentBlur(ruleForm.content)"
						maxlength="500"></el-input>
				</el-form-item>
				<el-form-item label="试题类型" prop="type" ref="type">
					<el-radio-group v-model="ruleForm.type" @change="typeChange">
						<el-radio :label="1">单选</el-radio>
						<el-radio :label="2">多选</el-radio>
						<el-radio :label="3">填空</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="考试范围" prop="scopeId" ref="scopeId">
					<el-select v-model="ruleForm.scopeId" filterable class="selectStyle">
						<el-option v-for="item in scopeData" :key="item.id" :label="item.scope" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="试题难度" prop="difficulty" ref="difficulty">
					<el-radio-group v-model="ruleForm.difficulty">
						<el-radio :label="1">简单</el-radio>
						<el-radio :label="2">普通</el-radio>
						<el-radio :label="3">困难</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="答案顺序" prop="fillReverse" ref="fillReverse" v-show="ruleForm.type===3">
					<el-radio-group v-model="ruleForm.fillReverse">
						<el-radio :label="false">不可颠倒</el-radio>
						<el-radio :label="true">可颠倒</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="出题人">
					 <!-- @input="formkeywordValue('author',ruleForm.author)" -->
					<el-input v-model="ruleForm.author" maxlength="50"></el-input>
				</el-form-item>
				<el-form-item label="选项A" prop="optionA" ref="optionA">
					 <!-- @input="formkeywordValue('optionA',ruleForm.optionA)" -->
					<el-input v-model="ruleForm.optionA" maxlength="50"></el-input>
				</el-form-item>
				<el-form-item label="选项B" prop="optionB" ref="optionB" v-show="!(ruleForm.type===3 && matchNum<2)"
					:rules="(matchNum>1)?rules.optionB:[{ required: false, message: '请输入选项B的内容', trigger: 'blur' }]">
					 <!-- @input="formkeywordValue('optionB',ruleForm.optionB)" -->
					<el-input v-model="ruleForm.optionB" maxlength="50"></el-input>
				</el-form-item>
				<el-form-item label="选项C" prop="optionC" ref="optionC" v-show="!(ruleForm.type===3 && matchNum<3)"
					:rules="(matchNum>2)?rules.optionC:[{ required: false, message: '请输入选项C的内容', trigger: 'blur' }]">
					 <!-- @input="formkeywordValue('optionC',ruleForm.optionC)" -->
					<el-input v-model="ruleForm.optionC" maxlength="50"></el-input>
				</el-form-item>
				<el-form-item label="选项D" prop="optionD" ref="optionD" v-show="!(ruleForm.type===3 && matchNum<4)"
					:rules="(matchNum>3)?rules.optionD:[{ required: false, message: '请输入选项D的内容', trigger: 'blur' }]">
					 <!-- @input="formkeywordValue('optionD',ruleForm.optionD)" -->
					<el-input v-model="ruleForm.optionD" maxlength="50"></el-input>
				</el-form-item>
				<el-form-item label="选项E" prop="optionE" ref="optionE" v-show="!(ruleForm.type===3 && matchNum<5)"
					:rules="(matchNum>4)?rules.optionE:[{ required: false, message: '请输入选项E的内容', trigger: 'blur' }]">
					 <!-- @input="formkeywordValue('optionE',ruleForm.optionE)" -->
					<el-input v-model="ruleForm.optionE" maxlength="50"></el-input>
				</el-form-item>
				<el-form-item label="选项F" prop="optionF" ref="optionF" v-show="!(ruleForm.type===3 && matchNum<6)"
					:rules="(matchNum>5)?rules.optionF:[{ required: false, message: '请输入选项F的内容', trigger: 'blur' }]">
					 <!-- @input="formkeywordValue('optionF',ruleForm.optionF)" -->
					<el-input v-model="ruleForm.optionF" maxlength="50"></el-input>
				</el-form-item>
				<el-form-item label="答案" prop="answer" ref="answer"
					:rules="(ruleForm.type==1||ruleForm.type==2)?rules.answer:[{ required: false, message: '请输入答案', trigger: 'blur' }]">
					<el-input v-model="ruleForm.answer" maxlength="6" @input="answerInput(ruleForm.answer)"></el-input>
				</el-form-item>
				<el-form-item label="提示" prop="tips" ref="tips">
					 <!-- @input="formkeywordValue('tips',ruleForm.tips)" -->
					<el-input type="textarea" v-model="ruleForm.tips" maxlength="200"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" size="small" @click="submitForm('ruleForm')">确定</el-button>
					<el-button size="small" @click="resetForm">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!-- 选中考试范围 -->
		<el-dialog title="更新考试范围" :visible.sync="dialogScope" :close-on-click-modal="false" class="scopeStyle">
			<el-select v-model="scopeIdValue" filterable placeholder="请选择考试范围" class="selectStyle">
				<el-option v-for="item in scopeData" :key="item.id" :label="item.scope" :value="item.id">
				</el-option>
			</el-select>
			<div slot="footer" class="dialog-footer">
				<el-button class="btnGreen" size="small" type="primary" @click="sureUpdate">更 新</el-button>
				<el-button class="cancelBtn" size="small" @click="cancelUpdate">取 消</el-button>
			</div>
		</el-dialog>
		<!-- 分页 -->
		<div class="block">
			<el-pagination layout="prev, pager, next" :total="total" :current-page.sync="currentPage"
				:page-size="pageSize"></el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'QuizList',
		data() {
			return {
				loading: false, //加载中
				loadingText: "",
				submitProcessing: false, //只能点击一次
				// 查询框内容
				scopeId: "",
				difficulty: "",
				type: "",
				contentKeyword: "",
				optionKeyword: "",
				// 考试范围数组
				scopeData: [],
				// 难易度
				difficultyData: [{
					difficulty: "简单",
					id: 1
				}, {
					difficulty: "普通",
					id: 2
				}, {
					difficulty: "困难",
					id: 3
				}],
				// 类型
				typeData: [{
					type: "单选",
					id: 1
				}, {
					type: "多选",
					id: 2
				}, {
					type: "填空",
					id: 3
				}],
				// 查询对应的key
				scopeIdKey: "",
				difficultyKey: "",
				typeKey: "",
				contentKeywordKey: "",
				optionKeywordKey: "",
				// 列表
				quizData: [],
				// 分页
				currentPage: 1,
				total: 0,
				pageNum: 1,
				pageSize: 20,
				// 复选框
				checkBoxShow: false,
				firstMounted: false, //第一次获取列表信息
				multipleSelection: [], //复选框勾选
				quizIds: [], //选中的id
				getRowKeys(row) {
					return row.id;
				},
				// 考试范围弹框
				dialogScope: false,
				scopeIdValue: "",
				// 编辑试题弹框
				dialogQuizEdit: false,
				// 问题的输入判断
				matchContentWarn1: false,
				matchContentWarn2: false,
				// 匹配括号
				matchNum: 0,
				ruleForm: {
					id: '',
					content: '',
					type: '',
					scopeId: '',
					difficulty: '',
					fillReverse: '',
					author: '',
					optionA: '',
					optionB: '',
					optionC: '',
					optionD: '',
					optionE: '',
					optionF: '',
					answer: '',
					tips: '',
				},
				rules: {
					content: [{
						required: true,
						validator: this.contentCheck,
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择试题类型',
						trigger: 'change'
					}],
					scopeId: [{
						required: true,
						message: '请选择考试范围',
						trigger: 'change'
					}],
					difficulty: [{
						required: true,
						message: '请选择试题难度',
						trigger: 'change'
					}],
					name: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
					optionA: [{
						required: true,
						message: '请输入选项A的内容',
						trigger: 'blur'
					}],
					optionB: [{
						required: true,
						message: '请输入选项B的内容',
						trigger: 'blur'
					}],
					optionC: [{
						required: true,
						message: '请输入选项C的内容',
						trigger: 'blur'
					}],
					optionD: [{
						required: true,
						message: '请输入选项D的内容',
						trigger: 'blur'
					}],
					optionE: [{
						required: true,
						message: '请输入选项E的内容',
						trigger: 'blur'
					}],
					optionF: [{
						required: true,
						message: '请输入选项F的内容',
						trigger: 'blur'
					}],
					answer: [{
						required: true,
						message: '请输入答案',
						trigger: 'blur'
					}],
				},
				// 点击编辑时暂且赋值
				nowrow: {}
			}
		},
		mounted() {
			this.getquizData();
		},
		watch: {
			// 监听点击分页
			currentPage(oldV, newV) {
				var that = this;
				that.handleCurrentChange(oldV);
			},
		},
		methods: {
			// 输入问题校验
			contentCheck(rule, value, callback) {
				if (value === '') {
					callback(new Error("请输入问题内容"));
				} else if (this.matchContentWarn1) {
					callback(new Error("填空时问题内容需含有英文'()'"));
				} else if (this.matchContentWarn2) {
					callback(new Error("填空时问题内容最多6个英文'()'"));
				} else {
					callback();
				}
			},
			// 答案只能输入A-F中的
			answerInput(val) {
				var that = this;
				that.ruleForm.answer = val.replace(/[^A-F]/g, "");
			},
			// 查询时关键字不能为空
			// contentKeywordValue(val) {
			// 	this.contentKeyword = val.replace(/\s+/g, "");
			// },
			keywordValue(key,val) {
				this[key]=val.replace(/\s+/g, "");
			},
			// 选项不能输入空格
			formkeywordValue(key,val){
				this.ruleForm[key]=val.replace(/\s+/g, "");
			},
			//表格样式
			headClass() {
				return "text-align: center;background:#D7A24A; color:#ffffff";
			},
			rowClass() {
				return "text-align: center;";
			},
			indexMethod(index) {
				return (this.pageNum - 1) * this.pageSize + index + 1;
			},
			// 表单内容填写判断
			contentBlur(val) {
				var that = this;
				const reg = /\(\)/g;
				that.matchNum = val.match(reg) ? val.match(reg).length : 0;
				that.typeChange(that.ruleForm.type);
			},
			// 切换试题类型时
			typeChange(val) {
				var that = this;
				const reg = /\(\)/g;
				that.matchNum = that.ruleForm.content.match(reg) ? that.ruleForm.content.match(reg).length : 0;
				// 当选中填空时
				if (val === 3) {
					if (!that.matchNum) {
						that.matchContentWarn1 = true;
					} else if (that.matchNum > 6) {
						that.matchContentWarn2 = true;
					} else {
						that.matchContentWarn1 = false;
						that.matchContentWarn2 = false;
					}
				} else {
					that.matchContentWarn1 = false;
					that.matchContentWarn2 = false;
					that.matchNum = 2; //单选多选情况下前两个必填
				}
				setTimeout(function() {
					that.$refs.ruleForm.validate(valid => {});
				}, 10)
			},
			// 更新考试范围
			updateScope() {
				var that = this;
				that.checkBoxShow = true;
			},
			// 勾选列表进行更新考试范围
			handleSelectionChange(val) {
				var that = this;
				that.multipleSelection = val;
			},
			// 点击确定更新考试范围，弹框选中范围
			confirmUpdateScope() {
				let that = this;
				that.quizIds = [];
				that.multipleSelection.forEach(item => {
					that.quizIds.push(item.id);
				})
				if (that.quizIds.length > 0) {
					that.dialogScope = true;
				} else {
					that.$notify({
						title: '警告',
						message: "当前还未选中需要更新的试题！",
						type: 'warning',
						duration: 2000
					});
				}
			},
			// 点击取消，勾选清空
			cancelUpdateScope() {
				var that = this;
				that.checkBoxShow = false;
				that.multipleSelection = []; //表格复选框置为空
				that.quizIds = [];
				that.scopeIdValue = "";
				that.$refs.multipleTable.clearSelection(); //表格复选框（清空选择）
			},
			sureUpdate() {
				var self = this;
				self.loading = true;
				self.$axios
					.post("/quiz/scope/update", {
						scopeId: self.scopeIdValue,
						quizIds: self.quizIds
					})
					.then(function(response) {
						self.$notify({
							title: '提示',
							message: '考试范围更新成功!',
							type: 'success',
							duration: 2000
						});
						self.dialogScope = false;
						self.scopeIdValue = "";
						self.cancelUpdateScope();
						self.handleCurrentChange(self.pageNum);
					})
					.catch(function(error) {
						self.loading = false;
						self.$notify({
							title: '失败',
							message: error,
							type: 'error',
							duration: 2000
						});
					});
			},
			cancelUpdate() {
				var that = this;
				that.dialogScope = false;
				that.scopeIdValue = "";
			},
			// 查询
			searchClick() {
				var that = this;
				if (navigator.onLine == true) {
					that.scopeIdKey = that.scopeId;
					that.difficultyKey = that.difficulty;
					that.typeKey = that.type;
					that.contentKeywordKey = that.contentKeyword;
					that.optionKeywordKey = that.optionKeyword;
					that.pageNum = 1;
					that.currentPage = 1;
					that.getquizData();
				} else {
					that.$alert('请检查当前网络!', '提示', {
						confirmButtonText: '确定',
						type: 'error',
						callback: action => {}
					});
				}
			},
			// 分页--页码的切换
			handleCurrentChange(oldV) {
				var that = this;
				that.dataList = [];
				that.pageNum = oldV;
				that.getquizData();
			},
			// 获取列表data
			getquizData() {
				var that = this;
				that.loading = true;
				that.$axios
					.post("/quiz/list", {
						scopeId: that.scopeIdKey,
						difficulty: that.difficultyKey,
						type: that.typeKey,
						contentKeyword: that.contentKeywordKey,
						optionKeyword: that.optionKeywordKey,
						pageIndex: that.pageNum - 1,
						pageSize: that.pageSize,
					})
					.then(function(res) {
						that.loading = false;
						that.quizData = res.data.data;
						that.total = res.data.totalCount;
						if (!that.firstMounted) {
							that.getScopeData();
						}
						that.firstMounted = true;
					})
					.catch(function(error) {
						that.loading = false;
						if (error == "登录失效, 请重新登录!") {
							console.log(error)
						} else {
							that.$alert(error, '提示', {
								confirmButtonText: '确定',
								type: 'error',
								callback: action => {}
							});
						}
					});
			},
			// 删除试题
			deleteClick(index, row) {
				var self = this;
				self.$confirm('确定要删除此条试题吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (self.submitProcessing) {
						return
					}
					self.submitProcessing = true;
					self.loading = true;
					self.$axios.put("/quiz/remove/" + row.id).then(function(response) {
						self.loading = false;
						self.submitProcessing = false;
						self.$notify({
							title: '提示',
							message: '试题删除成功!',
							type: 'success',
							duration: 2000
						});
						self.addClick = false;
						self.editShow = "@test-测试#";
						self.handleCurrentChange(self.pageNum);
					}).catch(function(error) {
						self.$notify({
							title: '失败',
							message: error,
							type: 'error',
							duration: 2000
						});
						self.loading = false;
						self.submitProcessing = false;
					});
				}).catch(() => {});
			},
			// 点击编辑
			editClick(index, row) {
				var that = this;
				that.dialogQuizEdit = true;
				that.nowrow = JSON.parse(JSON.stringify(row));
				setTimeout(function() {
					that.getDetail(row.id);
				}, 50)
			},
			// 首先根据id获取详情
			getDetail(id) {
				var that = this;
				that.$axios.get("/quiz/detail/" + id)
					.then(res => {
						that.ruleForm.id = res.data.id;
						that.ruleForm.content = res.data.content;
						that.ruleForm.type = res.data.type;
						that.ruleForm.scopeId = res.data.scopeId;
						that.ruleForm.difficulty = res.data.difficulty;
						that.ruleForm.fillReverse = res.data.fillReverse;
						that.ruleForm.author = res.data.author;
						that.ruleForm.optionA = res.data.optionA;
						that.ruleForm.optionB = res.data.optionB ? res.data.optionB : "";
						that.ruleForm.optionC = res.data.optionC ? res.data.optionC : "";
						that.ruleForm.optionD = res.data.optionD ? res.data.optionD : "";
						that.ruleForm.optionE = res.data.optionE ? res.data.optionE : "";
						that.ruleForm.optionF = res.data.optionF ? res.data.optionF : "";
						that.ruleForm.answer = res.data.answer;
						that.ruleForm.tips = res.data.tips ? res.data.tips : "";
						that.typeChange(that.ruleForm.type);
					})
					.catch(err => {
						that.$notify({
							title: '失败',
							message: err,
							type: 'error',
							duration: 2000
						});
					})
			},
			// 确定编辑
			submitForm(formName) {
				var that = this;
				that.loading = true;
				if (that.submitProcessing) {
					return;
				}
				that.submitProcessing = true;
				that.$refs[formName].validate((valid) => {
					if (valid) {
						var ruleFormObj = JSON.parse(JSON.stringify(that.ruleForm));
						var key = 0;
						var arr = [];
						var j=0;
						var options=true;//用于判断是否有不连续情况
						for (key in ruleFormObj) {
							var obj = {};
							if (key.indexOf('option') > -1) {
								// 先把匹配的options清空
								// ruleFormObj[key] = "";
								delete ruleFormObj[key];
								if(that.ruleForm[key] || that.ruleForm[key]==="0"){
									// 若提交时是填空 过滤多余答案
									if(that.ruleForm.type == 3){
										if(j<that.matchNum){
											ruleFormObj[key]=that.ruleForm[key];
										}
										j++;
									}else{
										// 连续情况
										if(options){
											ruleFormObj[key]=that.ruleForm[key];
										}else{
											// 选项不连续提示语
											if(that.ruleForm.type == 1){
												that.$notify({
													title: '失败',
													message: "单选题选项不连续",
													type: 'error',
													duration: 2000
												});
												that.loading = false;
												that.submitProcessing = false;
												return
											}else{
												that.$notify({
													title: '失败',
													message: "多选题选项不连续",
													type: 'error',
													duration: 2000
												});
												that.loading = false;
												that.submitProcessing = false;
												return
											}
										}
									}
								}else{
									options=false;
								}
							}
						}
						console.log(ruleFormObj)
						that.$axios.post("/quiz/edit", ruleFormObj)
							.then(res => {
								that.loading = false;
								that.submitProcessing = false;
								that.$notify({
									title: '提示',
									message: '试题编辑成功!',
									type: 'success',
									duration: 2000
								});
								that.handleCurrentChange(that.pageNum);
								that.beforeCloseEdit();
							})
							.catch(err => {
								that.loading = false;
								that.submitProcessing = false;
								that.$notify({
									title: '失败',
									message: err,
									type: 'error',
									duration: 2000
								});
							})
					} else {
						that.loading = false;
						that.submitProcessing = false;
						return false;
					}
				});
			},
			// 关闭之前编辑
			beforeCloseEdit() {
				var that = this;
				Object.assign(that.$data.ruleForm, that.$options.data().ruleForm)
				that.$refs['ruleForm'].resetFields();
				setTimeout(function() {
					that.dialogQuizEdit = false;
				}, 50)
			},
			// 取消编辑
			resetForm() {
				this.beforeCloseEdit();
			},
			// 获取考试范围
			getScopeData() {
				let self = this;
				self.$axios.post("/quizScope/list", {
					keyword: ""
				}).then(function(response) {
					self.scopeData = response.data;
				}).catch(function(error) {
					self.scopeData = [];
				});
			},

		}
	}
</script>

<style lang='scss' scoped>
	#myQuizList {
		background: #FFFFFF;
		padding: 15px;

		/* 查询 */
		.search {
			.searchButton {
				background: #000000;
				color: #FFFFFF;
			}

			.el-col {
				margin: 5px 0;
			}

			.el-select {
				width: 100%;
			}

			.el-button {
				margin-top: 5px;
			}
		}

		/* 列表 */
		.quizListTable {
			margin: 10px auto;
		}

		/* 分页 */
		.block {
			background: transparent;
		}
	}
</style>
